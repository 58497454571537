<template>
  <v-card :style="[{ borderTop: `4px solid ${mainColor}` }]" style="max-width: 320px; width: 320px" class="pa-2">
    <v-card-title class="pa-0 pb-2">
      <div class="d-flex justify-space-between align-center" style="width: 100%;">
        <h5 class="main--text">{{ title }}:</h5>

        <v-btn @click="$emit('close')" fab width="18px" height="18px" color="main" depressed
          ><v-icon small color="white">{{ icons.mdiClose }}</v-icon></v-btn
        >
      </div>

      <span style="font-size: .875rem">{{ entityName }}</span>
    </v-card-title>

    <v-divider :style="[{ color: mainColor }, { height: '4px' }]"></v-divider>

    <v-card-text class="pa-0 mt-2">
      <v-row v-if="show" no-gutters align="center" justify="space-around">
        <v-col>
          <p class="ma-0">{{ translations.startDate }}</p>

          <date-picker
            @selected-date="
              value => {
                setDate(value, 'startDate');
              }
            "
            :key="`a`"
            :dateProp="startDate"
          ></date-picker>
        </v-col>

        <v-col>
          <p class="ma-0">{{ translations.endDate }}</p>

          <date-picker
            @selected-date="
              value => {
                setDate(value, 'endDate');
              }
            "
            :key="`b`"
            :dateProp="endDate"
            :minDateProp="startDate.picker"
            :startDate="startDate.picker"
          ></date-picker>
        </v-col>
      </v-row>
    </v-card-text>

    <div class="d-flex justify-end mt-2">
      <v-btn @click="createItemHandler" class="white--text" color="main" small :disabled="disableCreateBtn">{{ translations.create }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mdiClose } from "@mdi/js";
import DatePicker from "@/components/DatePicker.vue";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { translations } from "@/utils/common";

export default {
  components: {
    DatePicker
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    entityGroup: {
      type: Object,
      required: true,
      default: () => {}
    },

    startingDate: {
      type: String,
      required: true
    },

    endingDate: {
      type: String,
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    startDate: { holder: "", picker: "" },
    endDate: { holder: "", picker: "" }
  }),

  mixins: [MomentMixin],

  methods: {
    setDate(value, property) {
      const { picker, holder } = value;
      this[property].picker = picker;
      this[property].holder = holder;
    },

    createItemHandler() {
      const { foreman, id, content, type } = this.entityGroup;
      const ids = id.split("-");

      const projectId = Number(ids[0].split("P")[1]);
      const employeeId = type === "EMPLOYEE" ? Number(ids[1].split("E")[1]) : null;
      const supplierId = type === "SUPPLIER" ? Number(ids[1].split("S")[1]) : null;

      const payload = {
        isForeman: foreman ? true : false,
        timelineId: null,
        employeeId: employeeId,
        supplierId: supplierId,
        startDateStr: this.startDate.picker,
        projectId: projectId,
        endDateStr: this.endDate.picker,
        name: content
      };

      this.$emit("create-item", payload);
    }
  },

  computed: {
    translations: () => translations,

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    icons: () => ({ mdiClose }),

    entityName() {
      return this.entityGroup?.content;
    },

    entityType() {
      return this.entityGroup.type;
    },

    disableCreateBtn() {
      if (this.startDate?.picker && this.endDate?.picker) return false;
      return true;
    },

    title() {
      if (this.entityType === "EMPLOYEE") return this.translations.addTimeForEmployee;
      return this.translations.addTimeForSupplier;
    }
  },

  watch: {
    show(newVal) {
      if (newVal === false) {
        this.startDate = { holder: "", picker: "" };
        this.endDate = { holder: "", picker: "" };
      }
    },

    startingDate(newVal) {
      if (newVal) {
        this.startDate.picker = newVal;
        this.startDate.holder = this.momentDate(newVal, "DD/MM/YYYY");
      }
    },

    endingDate(newVal) {
      if (newVal) {
        this.endDate.picker = newVal;
        this.endDate.holder = this.momentDate(newVal, "DD/MM/YYYY");
      }
    }
  }
};
</script>
